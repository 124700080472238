const projects = [
     {
        title: "A clone of twitter's interface",
        description: "It's a project that uses typescript with react to to imitate the social network called Twitter, using styled components for styling",
        link: "https://gabrielc.dev.br/twitter/",
        github: "https://github.com/Gabrielc476/twitter-clone",
        technologies: ["React,", " Styled-Components"]
    },
    {
        title: "A pokedex",
        description: "It's a project using pure HTML5, CSS and Javascript to make a short sized functional pokedex , and utilizing pokemon API   for data",
        link:"https://gabrielc.dev.br/pokedex",
        github: "https://github.com/Gabrielc476/pokedex",
        technologies: ["HTML,", " Javascript,", " CSS," , " Pokemon API"]
    },
    {
        title: "A clone of Netflix's interface",
        description: "One of my first projects utilizing react, copying Netflix interface with TMDB API providing all information and pictures for the catalogue",
        link:"https://gabrielc.dev.br/netflix/",
        github: "https://github.com/Gabrielc476/netflix-clone",
        technologies: ["React,", " Styled-Components"]
    },
    {
        title: "A mini pokemon game",
        description: "A project utilizing HTMl canvas for animation, Tiled maps for scenario and javascript to create a mini version of the game pokemon",
        link:"https://gabrielc.dev.br/projeto-pokemon/",
        github: "https://github.com/Gabrielc476/mini-pokemon",
        technologies: ["HTML Canvas,", " Javascript"]
    },
    {
        title: "A shopping cart using typescript",
        description: "One of my first projects using typscript together with react, it's a functional sidebar with data provided by an specialized API",
        link:"https://gabrielc.dev.br/carrinho/",
        github: "https://github.com/Gabrielc476/carrinho-de-compras",
        technologies: ["React,", " Styled-Components,", " TypeScript"]
    },
    {
        title: "A login form using React",
        description: "A project that utilizes react hooks function to make a functional login form for web applications",
        link:"https://gabrielc.dev.br/login/",
        github: "https://github.com/Gabrielc476/login",
        technologies: ["React"]
    }

]

export default projects