import { useState, useEffect } from 'react';
import './App.css'
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Intro from './components/Intro/Intro';
import Portfolio from './components/Portfolio/Portfolio';

import Sidebar from './components/Sidebar/Sidebar';
import Skills from './components/Skills/Skills';
import Socials from './components/Socials/Socials';
import logo from './Images/LOGO-GABRIEL.png'
import logoalt from './Images/logoalt.jpg' 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBars, faXmark} from '@fortawesome/free-solid-svg-icons'
import ResponsivePortfolio from './components/ResponsivePortfolio/ResponsivePortfolio';

function useWindowSize (){
  const [size, setSize] = useState([window.innerHeight, window.innerWidth])
  useEffect(() => {
    const handleResize = () =>{
      setSize([window.innerHeight, window.innerWidth])
    }
    window.addEventListener("resize", handleResize)
  }, [])
  return size
}


function App() {

  const [height, width] = useWindowSize()
  const [sidebarClick, setSidebarClick] = useState(false)

  const navClose = () => setSidebarClick(false)
  return (
    
    <>
    <div className="App">
      {width >= 600 ? (
        <div className='Header'>
          <img src ={logoalt}className='logo' alt='logo'></img>
          <div className='nav'>
            <a href='#about-title' className='nav-about'><spa>About</spa></a>
            <a href='#skills' className='skills-nav'><span>Skills</span></a>
            <a href='#work' className='work-nav'><span>Work</span></a>
            <a href='#contact' className='contact-nav'><span>Contact</span></a>
          </div>
        </div>
      ) : (<div className='Header-responsive'>
            <img src ={logoalt}className='logo-responsive' alt='logo'></img>
            <button className='sidebar-button' onClick={() => setSidebarClick(true)}><FontAwesomeIcon icon={faBars} className="sidebar-buttonIcon"/></button>
          </div>
          )}
      {sidebarClick ? (
          <div>
            <Sidebar className= 'sidebar' navClose={navClose}/>
            

          </div>
        
      ) : null}
      <Intro className="intro"/>
      <About className= "about" />
      <Skills className="skills"/>
      {width >= 600 ? (<Portfolio/>) : (<ResponsivePortfolio/>)}
      <Contact className= "contact"/>
      <Socials className='socials'/>
    </div>
    </>
  );
}

export default App;
