import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDownload} from '@fortawesome/free-solid-svg-icons'
import './Intro.css'

const Intro = () => {
    return(
        <div className='intro'>
            <p className='upper-title'>Hi my name is</p>
            <h1 className='title'>Gabriel Castro</h1>
            <h1 className='subtitle'>I'm a Computer Science student</h1>
            <p className='text'>I study Computer Science with focus in front-end development and I have a interest in Game development, currently I am looking for 
                job opportunities in the front-end development area
            </p>
            <a href='https://drive.google.com/drive/folders/1tY9JaRkWTmKknZ1hwQxEryIHpmaTt1Rz?usp=sharing' className='download-link'><button className='resume-download'><span>Download CV</span><FontAwesomeIcon icon={faDownload} className="download-icon"/></button></a>
        </div>
    )
}

export default Intro