import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faLinkedinIn, faGithub } from "@fortawesome/free-brands-svg-icons";
import './Socials.css'


const Socials = () => {
    return(
        <div className="socials-area">
            <a href="https://www.instagram.com/gabriel_cavalcante376/"><FontAwesomeIcon icon={faInstagram} className = 'instagram-icon'/></a>
            <a href="https://www.linkedin.com/in/gabriel-castro-298154209/"><FontAwesomeIcon icon={faLinkedinIn} className = 'linkedin-icon'/></a>
            <a href="https://github.com/Gabrielc476"><FontAwesomeIcon icon={faGithub} className='github-icon'/></a>
            <hr className="socials-line"></hr>
        </div>
    )
}
export default Socials