import React, {useState, useEffect} from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faArrowUpRightFromSquare, faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import projects from "./Box";
import "./ResponsivePortfolio.css"



const ResponsivePortfolio = () => {


    const [counter, setCounter] = useState(0)
    
    const works = projects
    
    function subtractNumber(){
        let num = counter
        if (num > 0){
            num--
        }
        setCounter(num)
    }
    function addNumber(){
        let limit = works.length
        let num = counter
        if (num <= limit - 1 ){
            num++
        }
        else {
            num = counter
        }
        setCounter(num)
    }

    return(
        
        <div className="Responsiveportfolio-area" id="work">
            <div className="Responsivehead">
                <p className="Responsiveportfolio-title">Some of my Work</p>
            </div>
            <div className="Responsivebox-area">
                <button className="left-arrow" onClick={subtractNumber}><FontAwesomeIcon className="arrow" icon={faArrowLeft}/></button>
                <div  className='Responsivebox' >
                            <div className="Responsivebox-icons">
                                <a href={works[counter].link}><FontAwesomeIcon icon={faFolder} className='Responsivefolder-icon'/></a>
                                <a href="#"><FontAwesomeIcon icon={faGithub}className="Responsivegithub-icon"/></a>
                                <a href={works[counter].link}><FontAwesomeIcon icon={faArrowUpRightFromSquare} className='Responsiveredirect-icon'/></a>
                            </div>
                            
                            <p className="Responsiveproject-title" >{works[counter].title}</p>
                            <p className="Responsiveproject-text">{works[counter].description}</p>
                            <div className="Responsivetechnologies"><p>javascript</p></div>
                            
                        </div>
                <button className="right-arrow" onClick={addNumber} disabled={counter >= works.length - 1  ? true : false}><FontAwesomeIcon icon={faArrowRight} className="arrow"/></button>    
            </div>
            <a className="repo-link" href="https://github.com/Gabrielc476?tab=repositories"><button className="Responsiverepo-button"><span>view my repositories</span></button></a>
        </div>
        
    )
}

export default ResponsivePortfolio