import React from "react";
import './About.css'

const About = () =>{
    return(
        <div className="about" id="about">
            <div className="about-title" id="about-title">
                
                <p className="about-titleText">About</p>
            </div>
            <hr className="linha"></hr>
            <div className="about-text">
                <p>I started in the world of programming in 2017 in a robotics class playing with arduino, then I realized that I really liked programming the project more than constructing it</p>
                <p>Since then I started studying programming at school with game development where I ended up working with unity engine and unreal 4. At college I started learning web-development a I've really grew fond of Javascript and React, now I make projects for all kinds of stuff ranging from login forms to mini games</p>
            </div>
            
        </div>
    )
}

export default About