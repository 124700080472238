import React from "react";
import './Contact.css'

const Contact = () =>{
    return(
        <div className="contact-area" id="contact">
            <p className="contact-title">Let's Talk</p>
            <div className="contact-textArea">
            <p className="contact-text">I'm currently looking for job opportunities to work in the area so if you would like to get in touch about it or anything else please feel free to send a message</p>
            </div>
            <a href="mailto:gabrielc.cavalcante@hotmail.com" className="email"><button className="contact-button"><span>Send message</span></button></a>
        </div>
    )
}

export default Contact