import React, {useState, useEffect} from "react";
import "./Portfolio.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import projects from "./Box";

function useWindowSize (){
    const [size, setSize] = useState([window.innerHeight, window.innerWidth])
    useEffect(() => {
      const handleResize = () =>{
        setSize([window.innerHeight, window.innerWidth])
      }
      window.addEventListener("resize", handleResize)
    }, [])
    return size
  }

const Portfolio = () => {

    const [height, width] = useWindowSize()
    
    const works = projects

     
   
    
    
    

    return(
        
        <div className="portfolio-area" id="work">
            <div className="head">
                <p className="portfolio-title">Some of my Work</p>
            </div>
            <div className="box-area">
                {works.map((project, index) => {
                    return(
                        <div key={index} className='box' >
                            <div className="box-icons">
                                <a href={project.link}><FontAwesomeIcon icon={faFolder} className='folder-icon'/></a>
                                <a href={project.github}><FontAwesomeIcon icon={faGithub}className="portfolioGithub-icon"/></a>
                                <a href={project.link}><FontAwesomeIcon icon={faArrowUpRightFromSquare} className='redirect-icon'/></a>
                            </div>
                            
                            <p className="project-title" >{project.title}</p>
                            <p className="project-text">{project.description}</p>
                            <div className="technologies"><p className="tech-text">{project.technologies}</p></div>
                            
                        </div>
                    )
                })}

            </div>
            <a className="repo-link" href="https://github.com/Gabrielc476?tab=repositories"><button className="repo-button"><span>view my repositories</span></button></a>
        </div>
        
    )
}

export default Portfolio