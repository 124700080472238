import React, {useState, useEffect} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faHtml5, faCss3Alt, faSquareJs, faReact} from '@fortawesome/free-brands-svg-icons'
import './Skills.css'

const Skills = () => {

    const [mouseFocus, setMouseFocus] = useState('')
    
    
    
    

    return(
        <div className="skills-display" id="skills">
            <p className="skills-title">My Skills</p>
            <div className="skills-line">
                <a href="https://developer.mozilla.org/en-US/docs/Glossary/HTML5" target='_blank'>
                    <div className="icon-skill" onMouseEnter= {() => setMouseFocus('html')} onMouseLeave= {() => setMouseFocus('')}>
                    <FontAwesomeIcon icon={faHtml5}  className= 'skill-brand'/>
                    {mouseFocus === 'html' ? (<p className="skill-text">HTML5</p>) : null}
                    </div>
                </a>
                <a href="https://developer.mozilla.org/en-US/docs/Web/CSS" target='_blank'>
                    <div className="icon-skill" onMouseEnter= {() => setMouseFocus('css')} onMouseLeave= {() => setMouseFocus('')}>
                    <FontAwesomeIcon icon={faCss3Alt}  className= 'skill-brand'/>
                    {mouseFocus === 'css' ? (<p className="skill-text">CSS</p>) : null}
                    </div>
                </a>
                <a href="https://www.javascript.com/" target="_blank">
                    <div className="icon-skill" onMouseEnter= {() => setMouseFocus('javascript')} onMouseLeave= {() => setMouseFocus('')}>
                    <FontAwesomeIcon icon={faSquareJs} className= 'skill-brand'/>
                    {mouseFocus === 'javascript' ? (<p className="skill-text">JAVASCRIPT</p>) : null}
                    </div>
                </a>
                <a href="https://reactjs.org/" target="_blank">
                    <div className="icon-skill" onMouseEnter= {() => setMouseFocus('react')} onMouseLeave= {() => setMouseFocus('')}>
                    <FontAwesomeIcon icon={faReact} className= 'skill-brand'/>
                    {mouseFocus === 'react' ? (<p className="skill-text">REACT</p>) : null}
                    </div>
                </a>
            </div>
        </div>
    )
}

export default Skills