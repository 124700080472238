import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faXmark} from "@fortawesome/free-solid-svg-icons";
import './Sidebar.css'
const Sidebar = (props) =>{
    
    return(
        <div className="sidebar-area">
            <div className="sidebar-links">
            <a href="#about" className="sidebar-about" onClick={() => props.navClose()}>About</a>
            <a href="#skills" className="sidebar-skills" onClick={() => props.navClose()}>Skills</a>
            <a href="#work" className="sidebar-work" onClick={() => props.navClose()}>Work</a>
            <a href="#contact" className="sidebar-contact" onClick={() => props.navClose()}>Contact</a>
            <a href="https://drive.google.com/drive/folders/1tY9JaRkWTmKknZ1hwQxEryIHpmaTt1Rz?usp=sharing"><button className="sidebar-cv" onClick={() => props.navClose()}><span>Download CV</span><FontAwesomeIcon icon={faDownload}/></button></a>
            </div>
           
            <button className="exit" onClick={() => props.navClose()}><FontAwesomeIcon icon={faXmark} className="exit-icon"/></button>
        
        </div>
    )
}

export default Sidebar